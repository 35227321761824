<template>
  <div class="content">
    <div id="verifyemail">
      <div class="container">
        <div v-if="$route.params.code == undefined">
          <h2>กรุณายืนยันตัวตนผ่านทาง Email ที่สมัครสมาชิก</h2>
          <p>หากท่านยังไม่ได้รับอีเมลยืนยัน  กรุณาดปุ่ม "ส่ง Verify Email" ด้านล่างนี้</p>
          <div class="form-forgot">
            <button class="btn btn-color-dark w-100" @click="sendVerify()">
              ส่ง Verify Email
            </button>
          </div>
        </div>
        <div v-else>
          <h1>กำลังทำการยืนยันตัวตน</h1>
          <br />
          <div v-loading="true"></div>
        </div>
      </div>
    </div>
  </div>
</template>
<style  scoped>
.btn-color-dark {
  padding: 8px 0 !important;
  width: 100%;
  display: block;
  text-align: center;
  margin-top: 30px;
}
</style>
<script>
import { HTTP } from "@/service/axios.js";
export default {
  props: {
    routeParams: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {};
  },
  async mounted() {
    this.setParams();
    await this.confirmEmail();
  },
  methods: {
    setParams() {
      if (this.routeParams != null) {
        this.email = this.routeParams.email;
      }
      if (this.email == undefined) {
        this.$router.push(`/home`);
      }
    },
    sendVerify() {
      let obj = {
        email: this.email,
      };
      HTTP.post(`api/verify`, obj)
        .then((res) => {
          console.log(res);
          if (res.data.success) {
            this.$message({
              message: "ส่งเมลยืนยันตัวตนสำเร็จ กรุณาตรวจสอบอีเมลของท่าน",
              type: "success",
              duration: 4000,
            });
          } else {
            this.alertFailError();
          }
        })
        .catch((e) => {
          this.alertCatchError(e);
          console.log("Verify error", e);
        });
    },
    confirmEmail() {
      let code = this.$route.params.code;
      if (code != undefined) {
        HTTP.get(`api/verify/` + code)
          .then((res) => {
            console.log(res);
            if (res.data.success) {
              this.$router.push(`/home`);
              setTimeout(() => {
                this.$message({
                  message: "ยืนยันตัวตนสำเร็จ",
                  type: "success",
                  duration: 4000,
                });
                this.openTabLogin();
              }, 400);
            } else {
              this.alertFailError();
            }
          })
          .catch((e) => {
            this.alertCatchError(e);
            console.log("Verify error", e);
          });
      }
      console.log(this.$route.params.code);
    },
    openTabLogin() {
      this.$store.commit("SET_MODAL", true);
      this.$store.commit("TAB_LOGIN", true);
      this.$store.commit("TAB_REGIS", false);
      this.$store.commit("TAB_FORGET", false);
    },
  },
};
</script>
